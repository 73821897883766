import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import IndexSections from "./IndexSections";
import ErrorPage from "./components/ErrorPage";
import LegalInformation from "./components/contracts/LegalInformation";
import PrivacyPolicy from "./components/contracts/PrivacyPolicy";
import TermsOfService from "./components/contracts/TermsOfService";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<IndexSections />} />
          <Route path="/legal-information" element={<LegalInformation />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
