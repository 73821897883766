import React from "react";
import CHeader from "./CHeader";

export default function LegalInformation() {
  return (
    <>
      <CHeader />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            特定商取引法に基づく表記 (Legal information)
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            株式会社ADDINは各事業を以下の法人に基づき運営しています。
          </p>
        </div>
        <div className="mt-6 mb-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                代表者名 (CEO/Director)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                佐々木 敬多 / Keita Sasaki
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                事業内容 (Services)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                システム開発会社：System Development <br />
                オンラインコーチング教育：Online Education
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                事業所所在地 (Office Location)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                株式会社ADDIN：東京都港区浜松町２丁目２番１５号
                浜松町ダイヤビル２F <br />
                株式会社ADDIN インドオフィス：Office No. 318, Amanora Chambers
                (East), Level - C3, 4th Floor, Amanora Park Town, Magarpatta
                Road, Hadapsar, Pune, Maharashtra
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                法人番号 (License No)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                株式会社ADDIN：5010401172639
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                資本金 (Capital)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                ¥1,000,000
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                支払方法 (PAYMENT)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                クレジット：CREDIT CARD (VISA/MasterCard/Amex/JCB) <br />
                銀行振込：BANK TRANSFER
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                銀行振込 (Bank Transfer)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                GMOあおぞらネット銀行
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                商品代金以外の必要料金 (Additional fee)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                消費税、決済手数料（銀行振込み時手数料）
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                商品の引き渡し時期 (Product delivery time)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                クレジットカード：決済完了後、すぐにご利用開始となります。
                <br />
                銀行振込：入金確認後、すぐにご利用開始となります。
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                支払い時期 (Payment timing)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                クレジットカード：申し込み時に決済
                <br />
                銀行振込：申し込みから2日以内にお振込み
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                返品・交換・キャンセル等 (Returns, Exchanges, Cancellations)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                契約締結時より8日間はクーリングオフが可能。
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                解約/契約解除などについて (Termination)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                利用規約に記載
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                価格 (Prices)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                無料カウンセリング：¥0
                <br />
                インディペンデンスコース：¥720,000（税込）
                <br className="hidden sm:block" />
                プロフェッショナルコース：¥950,000（税込）
                <br className="hidden sm:block" />
                エリートコース：¥1,200,000（税込）
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                必要な通信機器、通信回線環境 (Network environment)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                インターネット接続：10Mbps以上 <br />
                使用ソフト：ZOOM <br />
                <br />
                サポートOS <br />
                Mac：10.7以降搭載のMac OS X<br />
                Windows：7〜11 <br />
                <br />
                ブラウザ
                <br />
                Mac：Safari5~、Chrome <br />
                Windows：IE7~、Safari5~、Chrome
                <br />
                <br />
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                お問い合わせについて (Contact)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                up-lab@addin-global.com <br />
                {/* 電話番号：03-6771-9342 */}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
