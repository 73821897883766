export default function BreakWord() {
  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        {/* <p className="text-base font-semibold leading-7 text-yellow-500">
          現状
        </p>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Challenge
        </h2> */}
        <p className="mt-6 text-lg font-bold leading-loose text-gray-600 tracking-wide">
          挑戦しようとした事は実現できていますか？
          <br />
          一歩を踏み出す力、そして挑戦したい事を発掘する力。
          <br />
          得れるものは選択する力と行動力。
          <br />
          正直楽な道のりではありません。
          <br />
          だからこそ、誰もやらない。
        </p>
      </div>
    </div>
  );
}
