import React from "react";
import Men4 from "../../assets//Icons/clients/Men4.png";
import Women2 from "../../assets//Icons/clients/Women2.png";
import Men1 from "../../assets/Icons/clients/Men1.png";
import Men2 from "../../assets/Icons/clients/Men2.png";
import Men3 from "../../assets/Icons/clients/Men3.png";
import Men5 from "../../assets/Icons/clients/Men5.png";
import Women1 from "../../assets/Icons/clients/Women1.png";
import Women3 from "../../assets/Icons/clients/Women3.png";
import Women4 from "../../assets/Icons/clients/Women4.png";

const testimonials = [
  {
    body: "自分が何がやりたいのかが明確になり、自信をもって仕事に取り組めるようになりました！",
    author: {
      name: "S.Mさん",
      handle: "24歳 男性",
      imageUrl: Men1,
    },
  },
  {
    body: "独立は夢のまた夢だと思っていました。ですが、コーチのおかげで年収が三倍に。人生は踏み出すチャレンジだと思えました。",
    author: {
      name: "K.Mさん",
      handle: "28歳 女性",
      imageUrl: Women3,
    },
  },
  {
    body: "長所と短所が見つかり人生が生きやすくなりました。",
    author: {
      name: "E.Aさん",
      handle: "26歳 男性",
      imageUrl: Men2,
    },
  },
  {
    body: "介護職からIT業界に転職。ワークライフバランスが明らかに変化して毎日が楽しい！",
    author: {
      name: "T.Sさん",
      handle: "28歳女性",
      imageUrl: Women2,
    },
  },
  {
    body: "ずっと夢だった飲食店をオープン！！これからの未来が楽しみです！！",
    author: {
      name: "S.Kさん",
      handle: "27歳 男性",
      imageUrl: Men3,
    },
  },
  {
    body: "独立起業をずっと一年目から考えてました。しかし何も変わらないまま30代に突入。諦めていた時にコーチに出会い独立。本当に今があるのは、選択を変えたからだと自分に誇れます。",
    author: {
      name: "J.Oさん",
      handle: "32歳 女性",
      imageUrl: Women1,
    },
  },
  {
    body: "周りはいろいろ始めてる中何もできない自分がコンプレックスでした。ですが今は独立し、何も知らなかっただけなんだと。学べる環境がここにありました。",
    author: {
      name: "G.Nさん",
      handle: "30歳 男性",
      imageUrl: Men4,
    },
  },
  {
    body: "今の職場がすべてだと思っていました。将来独立の為転職し、年収１８０万円上がりました！！",
    author: {
      name: "M.Rさん",
      handle: "24歳 女性",
      imageUrl: Women4,
    },
  },
  {
    body: "独立して数年、毎日支払いに追われる不安な日々。そこでコーチに出会い、今までのズレを修正してくださり今の時代の戦い方を教えてくれました。とても大切なことを学べたと、私の人生にとって大きな財産です。",
    author: {
      name: "M.Oさん",
      handle: "31歳 男性",
      imageUrl: Men5,
    },
  },
];

export default function ClientMsg(props) {
  return (
    <div className="bg-white py-24 sm:py-32" ref={props.clients}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-yellow-500">
            受講生の声
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Client voices
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.handle}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      className="h-10 w-10 rounded-full object-cover bg-gray-50"
                      src={testimonial.author.imageUrl}
                      alt=""
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">
                        {testimonial.author.handle}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
