import Men from "../../assets/Images/men-image.jpg";

export default function Ads1() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="relative overflow-hidden rounded-lg">
          <div className="absolute inset-0">
            <img
              src={Men}
              alt=""
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="relative bg-gray-400 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
            <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 className="text-lg font-bold tracking-tight text-white sm:text-4xl">
                <span className="block sm:inline">今変わりたい</span>
                <br className="hidden sm:block" />
                <span className="block sm:inline">
                  「あなたの為のコーチングサービス」
                </span>
              </h2>
              <p className="mt-3 sm:text-xl text-white">
                キャリアコーチング
                <br className="block sm:hidden" />
                <span className="text-yellow-400 font-bold text-2xl"> ✖ </span>
                <br className="block sm:hidden" />
                SNSマーケティング
              </p>
              <p className="mt-3 sm:text-xl text-white">
                １対１のパーソナルトレーニング型
                <br className="block sm:hidden" />
                だからこその、
                <span className="text-yellow-400 font-bold sm:text-2xl">
                  圧倒的成長
                </span>
              </p>
              <a
                href="https://uplab.addin-global.com/"
                target="_blank"
                className="mt-8 block w-full rounded-md border border-transparent bg-yellow-400 px-8 py-3 text-base font-semibold text-white hover:bg-yellow-500 sm:w-auto"
                rel="noreferrer"
              >
                詳細はこちら
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
