import React from "react";
import CHeader from "./CHeader";

export default function PrivacyPolicy() {
  return (
    <>
      <CHeader />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            プライバシーポリシー (Privacy policy)
          </h3>
        </div>
        <div className="mt-6 mb-6 border-t border-gray-100">
          &emsp;株式会社ADDIN（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。受講生の皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
          <br />
          <br />
          第1条（プライバシー情報）
          <br />
          &emsp;プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
          <br />
          &emsp;プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
          <br />
          <br />
          第２条（プライバシー情報の収集方法）
          <br />
          &emsp;当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録などから収集することがあります。
          <br />
          &emsp;当社は、ユーザーについて、利用したサービスやソフトウエア、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。
          <br />
          <br />
          第３条（個人情報を収集・利用する目的）
          <br />
          &emsp;&emsp;&emsp;当社が個人情報を収集・利用する目的は、以下のとおりです。
          <br />
          &emsp;&emsp;&emsp;ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先などに関する情報を表示する目的
          <br />
          &emsp;&emsp;&emsp;ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的
          <br />
          &emsp;&emsp;&emsp;ユーザーの本人確認を行うために、氏名、生年月日、住所、電話番号などの情報を利用する目的
          <br />
          &emsp;&emsp;&emsp;ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的
          <br />
          &emsp;&emsp;&emsp;第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的
          <br />
          &emsp;&emsp;&emsp;ユーザーからのお問い合わせに対応するために、当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的
          <br />
          &emsp;&emsp;&emsp;上記の利用目的に付随する目的
          <br />
          <br />
          第４条（個人情報の第三者提供）
          <br />
          &emsp;&emsp;当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
          <br />
          &emsp;&emsp;法令に基づく場合
          <br />
          &emsp;&emsp;人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
          <br />
          &emsp; &emsp;
          公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
          <br />
          &emsp;&emsp;国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          <br />
          &emsp;&emsp;予め次の事項を告知あるいは公表をしている場合
          <br />
          &emsp;&emsp;利用目的に第三者への提供を含むこと
          <br />
          &emsp;&emsp;第三者に提供されるデータの項目
          <br />
          &emsp;&emsp;第三者への提供の手段または方法
          <br />
          &emsp;&emsp;本人の求めに応じて個人情報の第三者への提供を停止すること
          <br />
          &emsp;&emsp;前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。
          <br />
          &emsp;&emsp;当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          <br />
          &emsp;&emsp;合併その他の事由による事業の承継に伴って個人情報が提供される場合
          <br />
          &emsp;&emsp;個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき
          <br />
          <br />
          第５条（個人情報の開示）
          <br />
          &emsp;当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、１件あたり１、０００円の手数料を申し受けます。
          <br />
          &emsp;本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          <br />
          &emsp;当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          <br />
          &emsp;その他法令に違反することとなる場合
          <br />
          &emsp;前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
          <br />
          <br />
          第６条（個人情報の訂正および削除）
          <br />
          &emsp;ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。
          <br />
          &emsp;当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。
          <br />
          <br />
          第７条（個人情報の利用停止等）
          <br />
          &emsp;当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
          <br />
          <br />
          第８条（プライバシーポリシーの変更）
          <br />
          &emsp;本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。
          <br />
          &emsp;当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
          <br />
        </div>
      </div>
    </>
  );
}
