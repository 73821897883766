import React from "react";
import CHeader from "./CHeader";

export default function TermsOfService() {
  return (
    <>
      <CHeader />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            利用規約 (Terms of service)
          </h3>
        </div>
        <div className="mt-6 mb-6 border-t border-gray-100">
          &emsp;この利用規約（以下、「本規約」といいます。）は、株式会社ADDIN（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
          <br />
          <br />
          第1条（適用）
          <br />
          &emsp;本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          <br />
          <br />
          第2条（利用登録）
          <br />
          &emsp;登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
          <br />
          &emsp;当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
          <br />
          &emsp;利用登録の申請に際して虚偽の事項を届け出た場合
          <br />
          &emsp;本規約に違反したことがある者からの申請である場合
          <br />
          &emsp;その他、当社が利用登録を相当でないと判断した場合
          <br />
          <br />
          第3条（ユーザーIDおよびパスワードの管理）
          <br />
          &emsp;ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
          <br />
          &emsp;ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
          <br />
          <br />
          第4条（禁止事項）
          <br />
          &emsp;ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
          <br />
          &emsp;法令または公序良俗に違反する行為
          <br />
          &emsp;犯罪行為に関連する行為
          <br />
          &emsp;
          当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          <br />
          &emsp;当社のサービスの運営を妨害するおそれのある行為
          <br />
          &emsp;他のユーザーに関する個人情報等を収集または蓄積する行為
          <br />
          &emsp; 他のユーザーに成りすます行為
          <br />
          &emsp;
          当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          <br />
          &emsp; その他、当社が不適切と判断する行為
          <br />
          <br />
          第5条（本サービスの提供の停止等）
          <br />
          &emsp;
          当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          <br />
          &emsp;
          本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
          <br />
          &emsp;
          地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
          <br />
          &emsp;コンピュータまたは通信回線等が事故により停止した場合
          <br />
          &emsp;その他、当社が本サービスの提供が困難と判断した場合
          <br />
          &emsp;当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
          <br />
          <br />
          第6条（利用制限および登録抹消）
          <br />
          &emsp;当社は、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
          <br />
          &emsp;本規約のいずれかの条項に違反した場合
          <br />
          &emsp;登録事項に虚偽の事実があることが判明した場合
          <br />
          &emsp;その他、当社が本サービスの利用を適当でないと判断した場合
          <br />
          &emsp;当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          <br />
          <br />
          第7条（免責事項）
          <br />
          &emsp;当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。
          <br />
          &emsp;当社は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額（継続的サービスの場合には1か月分相当額）の範囲内においてのみ賠償の責任を負うものとします。
          <br />
          &emsp;当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          <br />
          <br />
          第8条（サービス内容の変更等）
          <br />
          &emsp;当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
          <br />
          <br />
          第9条（利用規約の変更）
          <br />
          &emsp;当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
          <br />
          <br />
          第10条（通知または連絡）
          <br />
          &emsp;ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
          <br />
          <br />
          第11条（権利義務の譲渡の禁止）
          <br />
          &emsp;ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
          <br />
          <br />
          第12条（準拠法・裁判管轄）
          <br />
          &emsp;本規約は、日本法に基づき解釈されるものとし、ユーザーと弊社の間で生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          <br />
        </div>
      </div>
    </>
  );
}
