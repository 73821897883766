import React from "react";

import India1 from "../../assets/Images/office/india-1.jpg";
import India3 from "../../assets/Images/office/india-3.jpg";
import India2 from "../../assets/Images/office/team.jpg";

import India4 from "../../assets/Images/office/india-4.jpg";

export default function AboutUs(props) {
  return (
    <div className="overflow-hidden bg-white py-32" ref={props.about}>
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our company
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              私たちは、現状にとどまることなく、常に新たな挑戦を追求しています。
              日々が新たな冒険の場となっています。
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              「本社は東京に位置し、2023年よりインドに支社を開設しました。
              新たな環境での経験は、新しい視点やビジネスの展開につながると信じています。
              未知の土地で触れる刺激は、革新的なアイデアを生み出し、新たな挑戦に立ち向かう原動力となります。
              私たちは、大切な仲間たちとともに、これからの新たな一歩に心躍らせています。」
              <br />
              あなたも一歩踏み出して挑戦しませんか？
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src={India2}
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src={India4}
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src={India1}
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src={India3}
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
